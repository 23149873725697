import './Info.css';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Map from '../../components/map/Map';
import { KindContact } from '../../components/detail/Detail';
import Blog from '../../components/blog/Blog';

const Info = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Header />
            <div className="info-container">
              <div className="info-container-main-img"></div>
              <KindContact />
            </div>
            <div className="info-container-box">
              <div className="info-container-box-how">
                <h1 className="info-container-box-how-title">입소안내</h1>
                <p className="info-container-box-how-desc">
                  국민건강보험공단에서 치매등급(시설등급)을
                  <br />
                  받으시면 국가지원을 받으실 수 있습니다.
                </p>
                <span className="info-container-box-how-bar">
                  국민건강보험공단 | 1577-1000
                </span>
                <p className="info-container-box-how-desc">
                  1등급~5등급 까지 구분되어 있으며, 시설등급을
                  <br />
                  받으시면 어느등급이든 이용가능합니다.
                </p>
              </div>
              <div className="info-container-box-need">
                <h1 className="info-container-box-need-title">
                  입소 시 준비물
                </h1>
                <p className="info-container-box-need-desc">
                  시설등급이 표시된 '장기요양급여인정서'
                  <br />
                  코로나 '음성' 확인 문자 혹은 확인서
                  <br />
                  입으시던 속옷과 편한 티셔츠 서너 벌<br />
                  병원가실 때 입으실 외투 1벌
                </p>
                <p className="info-container-box-need-desc">
                  *결핵 등 감염병 건강검진 확인서류(전화주시면 확인)
                  <br />
                  *물병, 칫솔, 로션, 기저귀 등은 시설에서 모두 무료지급
                  <br /> 되오니 준비하실 필요 없습니다.
                </p>
                <span className="info-container-box-need-bar">
                  궁금한 점은 전화주세요 | 032-511-8495
                </span>
              </div>
            </div>
            <div className="info-container-cost">
              <div className="info-container-cost-text">
                <h1 className="info-container-cost-text-title">요양원 비용</h1>
                <p className="info-container-cost-text-desc">
                  건강보험공단에서 정한 등급별 본인부담금은 모든 요양원이
                  <br />
                  동일합니다. <span>본인부담금</span>에 <span>식대</span>가
                  추가되어 비용을 산정합니다.
                </p>
                <div className="info-container-cost-text-img"></div>
                <p className="info-container-cost-text-desc">
                  보통 심한 와상환자가 아니고
                  <br />
                  경증치매의 경우 3등급이며,
                  <br />
                  <span>
                    월 비용 약 60만원대로
                    <br />
                    식비, 기저귀, 재활치료 모두 포함
                  </span>
                  입니다.
                  <br />
                  <br />
                  *경감대상자는 건강보험료에 따라 결정되어지며,
                  <br />
                  건강보험공단 동사무소에서 확인 가능합니다.
                  <br />
                  <br />
                  *금천햇살요양원은 소규모 요양원으로 대형요양원에
                  <br />
                  비해 본인부담금이 더 저렴합니다.
                </p>
              </div>
            </div>
            <Blog />
            <Map />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Info;
