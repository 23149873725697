import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { IntroBar } from '../../components/detail/Detail';
import { behabilitationIcon, careIcon, facilityIcon } from '../../img/Icons';
import {
  FacilityImg,
  FacilityIntro,
} from '../../components/facility-intro/Facility-intro';
import MainIntro from '../../components/main/Main-Intro';
import RehabilitationIntro from '../../components/rehabilitation-intro/Rehabilitation-Intro';
import { CareIntro } from '../../components/care-intro/Care-Intro';
import Map from '../../components/map/Map';
import Blog from '../../components/blog/Blog';
import { CareSlickIntro } from '../../components/slicks/Slicks';

const Main = () => {
  return (
    <>
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Header />
              <MainIntro />
              <IntroBar text="시설안내" Icon={facilityIcon} />
              <FacilityIntro />
              <FacilityImg />
              <IntroBar text="케어서비스" Icon={behabilitationIcon} />
              <RehabilitationIntro />
              <IntroBar text="치매프로그램" Icon={careIcon} />
              <CareIntro />
              <CareSlickIntro />
              <Blog />
              <Map />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Main;
