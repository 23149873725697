import { RehabilitationSlick } from '../slicks/Slicks';
import './Rehabilitation-Intro.css';

const RehabilitationIntro = () => {
  return (
    <>
      <div className="rehabilitation-intro">
        <div className="rehabilitation-intro-text">
          <h1 className="rehabilitation-intro-text-title">
            간호조무사 무려 2명 근무
            <br />
            전문 요양보호사도 추가
          </h1>
          <p className="rehabilitation-intro-text-desc">
            시설장 부부가 직접 어르신들을
            <br />
            케어하며 간호조무사와 요양보호사를
            <br />
            필수인력보다더 채용하여질
            <br />
            좋은 서비스를 제공합니다.
          </p>
        </div>
        <div className="rehabilitation-intro-img"></div>
      </div>
      <RehabilitationSlick />
    </>
  );
};

export default RehabilitationIntro;
