import './Map.css';
import { RenderAfterNavermapsLoaded, NaverMap, Marker } from 'react-naver-maps';
import { mapIcon } from '../../img/Icons';
import { IntroBar } from '../../components/detail/Detail';

function NaverMapAPI() {
  const navermaps = window.naver.maps;

  return (
    <NaverMap
      className="naver-map"
      mapDivId={'maps-getting-started-uncontrolled'} // default: react-naver-map
      defaultCenter={{ lat: 37.47704222796584, lng: 126.8983697324268 }} // 지도 초기 위치
      defaultZoom={15} // 지도 초기 확대 배율
      zoomControl={true}
      scrollWheel={false}
    >
      <Marker
        key={1}
        position={new navermaps.LatLng(37.47704222796584, 126.8983697324268)}
        animation={1}
        title="금천햇살요양원"
        onClick={() => {
          window.open('https://blog.naver.com/da6060');
        }}
      />
    </NaverMap>
  );
}

const Map = () => {
  return (
    <>
      <div className="after-review-img"></div>
      <IntroBar text="오시는 길" Icon={mapIcon} />
      <div className="map-container">
        <h1 className="map-container-title">금천햇살 요양원 오시는 길</h1>

        <div className="map-container-map" id="maps">
          <RenderAfterNavermapsLoaded
            className="home-container-map_box_map"
            ncpClientId="2xo4rokjm1"
            error={<p>Maps Load Error</p>}
            loading={<p>Maps Loading...</p>}
          >
            <NaverMapAPI />
          </RenderAfterNavermapsLoaded>
        </div>
        <div className="map-container-text">
          <h1 className="map-container-text-addr">
            주소 : 서울특별시 금천구 시흥대로 487 2~3층
          </h1>
          <div className="map-container-text-img"></div>
          <p className="map-container-text-desc">
            구로디지털단지역 6번 출구
            <br />
            가산디지털단지역 4번 출구
            <br />
            &gt; 도보 10분 거리
            <br />
            <br />
            버스운행 : 금천03(가산초교 방면)
            <br />
            5535, 5530, 500 등
          </p>
        </div>
        <a className="map-container-text-link" href="tel:02-852-5176">
          <strong>상담전화</strong> 02-852-5176
          <br />
          <span>지금 통화하기 버튼</span>
        </a>
      </div>
    </>
  );
};

export default Map;
