import { CareSlickIntro } from '../slicks/Slicks';
import './Care-Intro.css';

export const CareIntro = () => {
  return (
    <div className="care-intro">
      <div className="care-intro-text">
        <h1 className="care-intro-title">
          다양한 치매프로그램
          <br />
          전문사회복지사
        </h1>
        <p className="care-intro-desc">
          거실 2개에 다양한 프로그램을 이용하여
          <br />
          맞춤 치매 프로그램을 실시하고
          <br />
          외부강사와 전문인력이 도와드립니다
        </p>
        <span className="care-intro-sub-bar">
          치매 외부강사 | 전문 사회복지사
        </span>
      </div>
      <div className="care-intro-img"></div>
    </div>
  );
};

export const CareImg = () => {
  return (
    <div className="care-img">
      <CareSlickIntro />
    </div>
  );
};
