import { IntroBar, KindContact } from '../../components/detail/Detail';
import {
  FacilityImg,
  FacilityIntro,
} from '../../components/facility-intro/Facility-intro';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Map from '../../components/map/Map';
import { facilityIcon } from '../../img/Icons';
import './Facility.css';
import Blog from '../../components/blog/Blog';

const Facility = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Header />
            <div className="facility-container">
              <div className="facility-container-main-img"></div>
              <KindContact />
            </div>
            <IntroBar text="시설안내" Icon={facilityIcon} />
            <FacilityIntro />
            <FacilityImg />
            <div className="facility-container-box-items">
              <div className="facility-container-box-item">
                <div className="facility-container-box-item-text"></div>
                <div className="facility-container-box-item-img1"></div>
              </div>
              <div className="facility-container-box-item">
                <div className="facility-container-box-item-text">
                  <h1 className="facility-container-box-item-text-title">
                    채광이 좋은 넓은 거실
                    <br />
                    깨끗한 최신시설
                  </h1>
                  <p className="facility-container-box-item-text-desc">
                    외부생활이 힘든 어르신들이 큰 거실에서 산책하시고
                    <br />
                    재활치료 하실 수 있도록 설계
                  </p>
                  <div className="facility-container-box-item-img2"></div>
                </div>
              </div>
              <div className="facility-container-box-item">
                <div className="facility-container-box-item-text">
                  <h1 className="facility-container-box-item-text-title">
                    1번 거실에선 프로그램
                    <br />
                    2번 거실에선 휴식
                  </h1>
                  <p className="facility-container-box-item-text-desc">
                    거실이 2개가 가능한 것은 어르신에 비해 넓은 면적을
                    <br />
                    사용하고 있기 때문이며 다양한 활용 가능합니다.
                  </p>
                </div>
                <div className="facility-container-box-item-img3"></div>
              </div>
              <div className="facility-container-box-item">
                <div className="facility-container-box-item-text">
                  <h1 className="facility-container-box-item-text-title">
                    상급병실료 없는
                    <br />
                    채광좋은 2인~4인 룸
                  </h1>
                  <p className="facility-container-box-item-text-desc">
                    어르신들 방이 전부 채광이 좋고 기저귀 관리를
                    <br />
                    밖에서 따로 해 시설전체가 쾌적합니다.
                  </p>
                </div>
                <div className="facility-container-box-item-img4"></div>
              </div>
            </div>
            <Blog />
            <Map />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Facility;
