import Blog from '../../components/blog/Blog';
import { IntroBar, KindContact } from '../../components/detail/Detail';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Map from '../../components/map/Map';
import RehabilitationIntro from '../../components/rehabilitation-intro/Rehabilitation-Intro';
import { behabilitationIcon } from '../../img/Icons';
import './Rehabilitation.css';

const Rehabilitation = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Header />
            <div className="rehabilitation-container">
              <div className="rehabilitation-container-main-img"></div>
              <KindContact />
            </div>
            <IntroBar text="케어서비스" Icon={behabilitationIcon} />
            <RehabilitationIntro />
            <Blog />
            <Map />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Rehabilitation;
