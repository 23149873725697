import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import './Slicks.css';
import rehabilitationBg1 from '../../img/rehabilitation-intro/rehabilitation-intro-1.jpg';
import rehabilitationBg2 from '../../img/rehabilitation-intro/rehabilitation-intro-2.jpg';
import rehabilitationBg3 from '../../img/rehabilitation-intro/rehabilitation-intro-3.jpg';
import rehabilitationBg4 from '../../img/rehabilitation-intro/rehabilitation-intro-4.jpg';

import careBg1 from '../../img/care-intro/care-1.jpg';
import careBg2 from '../../img/care-intro/care-2.jpg';
import careBg3 from '../../img/care-intro/care-4.jpg';
import careBg4 from '../../img/care-intro/care-5.jpg';

const careBgs = [careBg1, careBg2, careBg3, careBg4];

const rehabilitationBgs = [
  rehabilitationBg1,
  rehabilitationBg2,
  rehabilitationBg3,
  rehabilitationBg4,
];

export const RehabilitationSlick = () => {
  const settings = {
    customPaging: function (i) {
      return (
        <a href="/#endregion">
          {<img alt="slick-img" src={rehabilitationBgs[i]} />}
        </a>
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="rehabilitation-slick-container">
      <div>
        <Slider {...settings}>
          <div>
            <img alt="slick-img" src={rehabilitationBg1} />
            <p>어르신 전담 매니저</p>
          </div>
          <div>
            <img alt="slick-img" src={rehabilitationBg2} />
            <p>감정돌봄 서비스</p>
          </div>
          <div>
            <img alt="slick-img" src={rehabilitationBg3} />
            <p>가족같은 돌봄서비스</p>
          </div>
          <div>
            <img alt="slick-img" src={rehabilitationBg4} />
            <p>건강체크 서비스</p>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export const CareSlick = () => {
  const settings = {
    customPaging: function (i) {
      return (
        <a href="/#endregion">{<img alt="slick-img" src={careBgs[i]} />}</a>
      );
    },
    dots: true,
    dotsClass: 'care-slick slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="care-slick-container-main">
      <div>
        <Slider {...settings}>
          <div>
            <img alt="slick-img" src={careBg1} />
            <p>치매프로그램 - 그림그리기</p>
          </div>
          <div>
            <img alt="slick-img" src={careBg2} />
            <p>노래부르고 대화하기</p>
          </div>
          <div>
            <img alt="slick-img" src={careBg3} />
            <p>사회복지사와 함께 프로그램 하기</p>
          </div>
          <div>
            <img alt="slick-img" src={careBg4} />
            <p>콩고르기 등 오감 맞춤 프로그램</p>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export const CareSlickIntro = () => {
  const settings = {
    customPaging: function (i) {
      return (
        <a href="/#endregion">{<img alt="slick-img" src={careBgs[i]} />}</a>
      );
    },
    dots: true,
    dotsClass: 'care-slick slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="care-slick-container-intro">
      <div>
        <Slider {...settings}>
          <div>
            <img alt="slick-img" src={careBg1} />
            <p>프로그램 - 직접상추심기</p>
          </div>
          <div>
            <img alt="slick-img" src={careBg2} />
            <p>주일 예배 드리기(기독교 어르신만)</p>
          </div>
          <div>
            <img alt="slick-img" src={careBg3} />
            <p>맞춤프로그램 - 송편만들기</p>
          </div>
          <div>
            <img alt="slick-img" src={careBg4} />
            <p>모든 어르신 생신잔치 진행</p>
          </div>
        </Slider>
      </div>
    </div>
  );
};
