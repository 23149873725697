import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-logo"></div>
      <p className="footer-info">
        국민건강보험공단 장기요양기관 요양원
        <br />
        tel. 02- 851-5176
        <br />
        adress. 서울특별시 금천구 시흥대로 487 2층, 3층
        <br />
        COPYRIGHT(C) 금천햇살요양원. ALL RIGHT RESERVED
      </p>
    </footer>
  );
};

export default Footer;
