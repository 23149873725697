import './Facility-intro.css';

export const FacilityIntro = () => {
  return (
    <>
      <div className="facility-intro">
        <div className="facility-intro-text">
          <h1 className="facility-intro-title">
            직주근접성 우수!
            <br />
            도심형 최신 요양원
          </h1>
          <p className="facility-intro-desc">
            서울 도심인 금천구 독산동에 위치
            <br />
            채광이 우수한 최신건물로 안전강화
            <br />
            장애인 시설 구비로 안전 강화
            <br />
          </p>
          <span className="facility-intro-sub-bar">
            협력병원 | 의료법인 희명종합병원
          </span>
          <strong className="facility-intro-sub-notice">
            &#8251; 어르신의 병원진료가 필요한 경우 시설장 동행
          </strong>
        </div>
        <div className="facility-intro-img"></div>
      </div>
    </>
  );
};

export const FacilityImg = () => {
  return (
    <div className="facility-img">
      <div className="facility-img-text">
        <h1 className="facility-img-text-title">
          채광이 좋은 거실 2개
          <br className="facility-img-text-title-br" />
          바닥생활 가능한 가정집 분위기
        </h1>
        <p className="facility-img-text-desc">
          어르신들에게 다양한 휴식을 위해 거실 2개!
          <br />
          인원수에 비해 넓은 시설로 어르신 생활 공간이 넉넉합니다
        </p>
      </div>
      <div className="facility-img-img">
        <div className="facility-img-img-item1"></div>
        <div className="facility-img-img-item2"></div>
        <div className="facility-img-img-item3"></div>
        <div className="facility-img-img-item4"></div>
        <div className="facility-img-img-item5"></div>
        <div className="facility-img-img-item6"></div>
      </div>
    </div>
  );
};
