import './Blog.css';

const Blog = () => {
  return (
    <div className="facility-container-blog">
      <h1 className="facility-container-blog-title">
        금천햇살요양원 블로그 바로가기
      </h1>
      <div className="facility-container-blog-list">
        <div className="facility-container-blog-list-item">
          <span>&raquo;</span>
          <a
            target="_blank"
            href="https://blog.naver.com/wys54321/222288782895"
            rel="noreferrer"
          >
            서울노인요양원 '금천햇살요양원' 소개
          </a>
        </div>
        <div className="facility-container-blog-list-item">
          <span>&raquo;</span>
          <a
            target="_blank"
            href="https://blog.naver.com/wys54321/222295966460"
            rel="noreferrer"
          >
            금천구 구로구 동작구 요양원은 여기가 으뜸!
          </a>
        </div>
        <div className="facility-container-blog-list-item">
          <span>&raquo;</span>
          <a
            target="_blank"
            href="https://blog.naver.com/wys54321/222306087846"
            rel="noreferrer"
          >
            노인요양원, 서울에선 금천햇살요양원!
          </a>
        </div>
      </div>
    </div>
  );
};

export default Blog;
