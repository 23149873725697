import Blog from '../../components/blog/Blog';
import { CareIntro } from '../../components/care-intro/Care-Intro';
import { IntroBar, KindContact } from '../../components/detail/Detail';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Map from '../../components/map/Map';
import { CareSlick } from '../../components/slicks/Slicks';
import { behabilitationIcon } from '../../img/Icons';
import './Care.css';

const Care = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Header />
            <div className="care-container">
              <div className="care-container-main-img"></div>
              <KindContact />
            </div>
            <IntroBar text="치매프로그램" Icon={behabilitationIcon} />
            <CareIntro />
            <div className="care-container-box-items">
              <div className="care-container-box-item">
                <div className="care-container-box-item-img1"></div>
                <p>맞춤프로그램 - 직접 식물 키우기</p>
              </div>
              <div className="care-container-box-item">
                <div className="care-container-box-item-img2"></div>
                <p>주일 예배 드리기(기독교 어르신만)</p>
              </div>
              <div className="care-container-box-item">
                <div className="care-container-box-item-img3"></div>
                <p>사회복지사와 함께 프로그램 하기</p>
              </div>
              <div className="care-container-box-item">
                <div className="care-container-box-item-img4"></div>
                <p>맞춤프로그램 - 송편만들기</p>
              </div>
              <div className="care-container-box-item">
                <div className="care-container-box-item-img5"></div>
                <p>모든 어르신 생신잔치 진행</p>
              </div>
            </div>
            <CareSlick />
            <Blog />
            <Map />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Care;
